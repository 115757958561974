<script label="ts">
import { css } from 'emotion/dist/emotion.umd.min.js';

const spinner = css`
  display: inline-block;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-width: 4px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  animation: spinAround 700ms infinite linear;
  border-radius: 50%;
`;

</script>

<div class={spinner} />
