// @ts-ignore TODO: Missing types
import { css } from 'emotion/dist/emotion.umd.min.js';
export const Pronunciation = css `
  margin-bottom: 48px;
`;
export const ExplanationWrapper = css `
  margin-bottom: 32px;
`;
export const RepoLinkWrapper = css `
  display: flex;
  margin-bottom: 16px;
`;
export const RepoName = css `
  margin-right: 8px;
`;
