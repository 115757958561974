<script lang="ts">
// @ts-ignore TODO: Missing types
import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
// @ts-ignore TODO: Missing types
import { css } from 'emotion/dist/emotion.umd.min.js';

import Loading from 'components/Loading.svelte';
import theme from 'theme';

import type { GetColumns } from './types';

const StyledDataTable = css`
  display: flex;
  padding: 0 40px;
`;

const TableHeader = css`
  padding: 8px 40px;
  border: 1px solid ${theme.colors.grey};
  border-bottom: 0;
`;

const HeaderCell = css`
  font-weight: 700;
`;

const TableTitle = css`
  color: ${theme.colors.mainBlack};
`;

export let name: string = '';
export let columns: GetColumns = [];
export let isLoading: boolean | undefined = false;
</script>

<div class={TableHeader}>
  <h4 class={TableTitle}>{name}</h4>
</div>

<DataTable class={StyledDataTable} table$aria-label={name}>
  <Head>
    <Row>
      {#each columns as column}
        <Cell class={HeaderCell} style={column?.width ? `width: ${column?.width};` : ''}>
          {column?.name}
        </Cell>
      {/each}
    </Row>
  </Head>

  <Body>
    {#if isLoading}
      <Loading />
    {:else}
      <!-- TODO: Find better way how to render dynamic cell content -->
      <slot />
    {/if}
  </Body>
</DataTable>
