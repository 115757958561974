const colors = {
    oooBlack: '#121213',
    oooBlue: '#1A1BFF',
    mainBlack: '#000000de',
    grey: '#E4E4E4',
    darkGrey: '#495057',
    white: '#fff',
    green: '#00C107',
    orange: '#e39802',
    red: '#ED0000',
};
export default { colors };
