<script lang="ts">
import { slide } from 'svelte/transition';
import { cubicInOut } from 'svelte/easing';
// @ts-ignore TODO: Missing types
import { css } from 'emotion/dist/emotion.umd.min.js';
import Tooltip, { Wrapper as TooltipWrapper } from '@smui/tooltip';

import Loading from 'src/components/Loading.svelte';
import theme from 'src/theme';

import type { Project } from '../types';
import ReposList from '../ReposList/ReposList.svelte';
import Spinner from 'src/components/spinner.svelte';

const PanelWrap = (isExpanded: boolean) => css`
  transition: margin 0.5s ease-out;

  ${isExpanded && `margin: 16px 0; background: ${theme.colors.grey};`}
`;

const ProjectPanel = (isLoading: boolean) => css`
  cursor: ${isLoading ? 'auto' : 'pointer'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  background: ${theme.colors.white};
  color: ${theme.colors.oooBlack};
  padding: 16px 32px;
  border-bottom: 1px solid ${theme.colors.grey};
  transition: background 0.2s;

  &:hover {
    background: ${!isLoading ? theme.colors.grey : ''};
  }
`;

const LogoWrap = css`
  margin-left: 16px;
  margin-right: auto;
  width: 50px;

  img {
    max-width: 100%;
  }
`;

const StatusesWrap = css`
  display: flex;
  align-items: center;
`;

const StatusIconButton = css`
  cursor: pointer;
  background: transparent;
  border: none;
`;

const projectLogoMap: Record<string, string> = {
  Coinflip: 'assets/icons/coinflip.svg',
  Lifebrand: 'assets/icons/lifebrand.svg',
  Crave: 'assets/icons/crave.svg',
  Lluna: 'assets/icons/lluna.svg',
  'Supertutor TV': 'assets/icons/supertutor-tv.svg',
  Simyra: 'assets/icons/simyra.svg',
  Vette: 'assets/icons/vette.svg',
  Nasdaq: 'assets/icons/nasdaq.png',
};

export let projectList: Project[] = [];
export let isLoading: boolean = false;

let currentExpanded: number | undefined = undefined;

const setCurrentExpanded = (idx: number) =>
  (currentExpanded = idx === currentExpanded ? undefined : idx);

</script>

{#if isLoading && !projectList.length}
  <Loading />
{:else}
  {#each projectList as project, idx}
    <div class={PanelWrap(currentExpanded === idx)}>
      <div
        on:click={() => (!isLoading ? setCurrentExpanded(idx) : '')}
        class={ProjectPanel(isLoading)}
      >
        <h3>{project?.name}</h3>
        <div class={LogoWrap}>
          {#if project?.name}<img src={projectLogoMap[project.name]} alt={project?.name} />{/if}
        </div>
        {#if isLoading}
          <Spinner />
        {:else if currentExpanded !== idx}
          <div class={StatusesWrap}>
            {#if project.hasWarnings}
              <TooltipWrapper>
                <button class={StatusIconButton}>⚠️</button>
                <Tooltip xPos="center">
                  One or more
                  {project.name}'s repos has warnings to be taken care of ☝️, click to see more
                </Tooltip>
              </TooltipWrapper>
            {/if}

            {#if project.hasErrors}
              <TooltipWrapper>
                <button class={StatusIconButton}>❌</button>
                <Tooltip xPos="center">
                  {project.name}
                  has errors in one or more repos 🥲, click to see more
                </Tooltip>
              </TooltipWrapper>
            {/if}

            {#if !project.hasErrors && !project.hasWarnings}
              <TooltipWrapper>
                <button class={StatusIconButton}>✅</button>
                <Tooltip xPos="center">All fixed for {project.name} 🥳, click to see more</Tooltip>
              </TooltipWrapper>
            {/if}
          </div>
        {/if}
      </div>

      {#if currentExpanded === idx && !isLoading}
        <div transition:slide={{ duration: 350, easing: cubicInOut }}>
          {#each project?.repos as repo, repoIdx}
            <ReposList repo={repo} id={repoIdx} />
          {/each}
        </div>
      {/if}
    </div>
  {/each}
{/if}
