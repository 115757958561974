<script lang="ts">
// @ts-ignore TODO: Missing types
import { Link } from 'svelte-routing';
// @ts-ignore TODO: Missing types
import { css } from 'emotion/dist/emotion.umd.min.js';

const NavLinkContainer = css`
  font-size: 16px;
  font-weight: 400;
`;

export let to: string = '';

type GetProps = {
  location: any;
  href: string;
  isPartiallyCurrent: string;
  isCurrent: string;
};

const getProps = ({ location, href, isPartiallyCurrent, isCurrent }: GetProps) => {
  const isActive = href === '/' ? isCurrent : isPartiallyCurrent || isCurrent;

  return isActive ? { class: 'active' } : {};
};
</script>

<div class={NavLinkContainer}>
  <Link to={to} getProps={getProps}>
    <slot />
  </Link>
</div>
