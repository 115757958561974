<script lang="ts">
// @ts-ignore TODO: Missing types
import Chip from '@smui/chips';
// @ts-ignore TODO: Missing types
import { css } from 'emotion/dist/emotion.umd.min.js';

import type { severities } from 'types';
import theme from 'theme';

import { ChipSharedStyles } from './SeverityChip.styles';

const variants: Record<keyof typeof severities, string> = {
  PASS: css`
    ${ChipSharedStyles};
    background-color: ${theme.colors.green};
  `,
  WARNING: css`
    ${ChipSharedStyles};
    background-color: ${theme.colors.orange};
  `,
  ERROR: css`
    ${ChipSharedStyles};
    background-color: ${theme.colors.red};
  `,
};

export let variant: severities;
</script>

<Chip class={variants[variant]}>
  <slot />
</Chip>
