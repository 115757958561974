<script lang="ts">
import { onMount } from 'svelte';
import dayjs from 'dayjs';
import { css } from 'emotion/dist/emotion.umd.min.js';
import theme from 'theme';
import appStore from 'store/appStore';

import ProjectList from './ProjectList/ProjectList.svelte';
import type { Project, Check, Info } from './types';
import { severities } from 'src/types';

let projectList: Project[] = [];
let isLoading: boolean = false;

interface ProjectsRaw {
  [key: string]: {
    name: string;
    repos: {
      [key: string]: {
        name: string;
        checks: Check[];
        info: Info[];
      };
    };
  };
}

interface ScansResponse {
  scanTime: string;
  projects: ProjectsRaw;
}

const normalizedProjectList = (projectsRaw: ProjectsRaw) => {
  return Object.values(projectsRaw).map((projectRaw) => {
    const repos = Object.values(projectRaw?.repos || []);
    const hasErrors = repos.some((repo) =>
      repo.checks.some((check) => check.severity === severities.ERROR)
    );
    const hasWarnings = repos.some((repo) =>
      repo.checks.some((check) => check.severity === severities.WARNING)
    );

    return {
      ...projectRaw,
      hasErrors,
      hasWarnings,
      repos,
    };
  });
};

const updateScanResult = (scanList: ScansResponse | undefined) => {
  const lastScanTime = dayjs(scanList?.scanTime || '').format('DD.MM.YYYY HH:mm:ss');
  projectList = normalizedProjectList(scanList?.projects || {});

  appStore.updateLastScanTime(lastScanTime);
};

const fetchScans = async (url: string): Promise<ScansResponse | undefined> => {
  isLoading = true;

  try {
    const response = await fetch(url);

    if (!response?.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    if (response?.ok) {
      isLoading = false;

      return response.json();
    }
  } catch (err) {
    console.error(err);

    isLoading = false;
  }
};

const refetchScans = async () => {
  const refetchUrl = 'https://us-central1-ooo-sentinel.cloudfunctions.net/app/scans';
  const url = 'https://storage.googleapis.com/ooo-sentinel-scan/scandata.json';
  const newScannedResult = (await fetchScans(refetchUrl)) ?? (await fetchScans(url));
  updateScanResult(newScannedResult);
};

const PageHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const reScan = css`
  cursor: pointer;
  border-radius: 24px;
  padding: 10px;

  &:hover {
    color: ${theme.colors.white};
    background-color: ${theme.colors.darkGrey};
  }
`;

const loadingText = css`
  color: ${theme.colors.white};
  font-size: 12px;
`;

onMount(async () => {
  const url = 'https://storage.googleapis.com/ooo-sentinel-scan/scandata.json';
  const scanList = await fetchScans(url);
  updateScanResult(scanList);
});

</script>

<div class="page-container">
  <div class={PageHeader}>
    <h1>Projects</h1>
    {#if !isLoading}
      <button class={reScan} on:click={() => refetchScans()}>Run checks </button>
    {:else}
      <div class={loadingText}>Please be patient, this might take a while...</div>
    {/if}
  </div>
  <ProjectList projectList={projectList} isLoading={isLoading} />
</div>
