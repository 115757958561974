<script>
import { css } from 'emotion/dist/emotion.umd.min.js';

import store from 'store/appStore';
import RedirectLink from 'components/RedirectLink.svelte';
import theme from 'theme';

import NavLink from './NavLink.svelte';

const NavigationContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  position: sticky;
  top: 0;
  background-color: ${theme.colors.oooBlack};
  z-index: 1000;
`;

const NavigationLinksWrapper = css`
  display: flex;

  > * {
    margin-right: 24px;
  }
`;

const OakslabLogo = css`
  height: 12px;
`;

$: lastScanTime = $store.lastScanTime;

let oakslabLogo = 'assets/icons/oakslab-white.svg';
</script>

<div class={NavigationContainer}>
  <nav class={NavigationLinksWrapper}>
    <RedirectLink link="https://www.oakslab.com/">
      <img class={OakslabLogo} src={oakslabLogo} alt="Oakslab Website" />
    </RedirectLink>
    <NavLink to="/">Projects</NavLink>
    <NavLink to="about">About Sentinel</NavLink>
  </nav>

  {#if lastScanTime}
    <div>Last scan: {lastScanTime}</div>
  {/if}
</div>
