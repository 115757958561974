export const getChecksColumns = () => [
    {
        name: 'Code',
        dataKey: ['code'],
        width: '35%',
    },
    {
        name: 'Message',
        dataKey: ['message'],
        width: '50%',
    },
    {
        name: 'Severity',
        dataKey: ['severity'],
        width: '150px',
    },
];
export const getInfoColumns = () => [
    {
        name: 'Code',
        dataKey: ['code'],
        width: '35%',
    },
    {
        name: 'Message',
        dataKey: ['message'],
    },
];
