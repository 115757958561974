<script lang="ts">
import { slide } from 'svelte/transition';
import { cubicInOut } from 'svelte/easing';
// @ts-ignore TODO: Missing types
import { Row, Cell } from '@smui/data-table';
import Tooltip, { Wrapper as TooltipWrapper } from '@smui/tooltip';
// @ts-ignore TODO: Missing types
import { css } from 'emotion/dist/emotion.umd.min.js';

import DataGrid from 'components/DataGrid/DataGrid.svelte';
import SeverityChip from 'components/SeverityChip/SeverityChip.svelte';
import RedirectLink from 'components/RedirectLink.svelte';
import theme from 'src/theme';
import { severities } from 'src/types';

import { getChecksColumns, getInfoColumns } from '../ProjectList/ProjectList.config';
import type { Repo } from '../types';

const GithubIcon = css`
  height: 36px;
  width: 36px;
  margin-left: 8px;
`;

const RepoPanel = css`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 8px 32px;
  background: ${theme.colors.white};
  color: ${theme.colors.oooBlack};

  &:hover {
    color: ${theme.colors.darkGrey};
  }
`;

const IconsWrap = css`
  display: flex;
  align-items: center;
  margin-left: auto;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export let id: number = 0;
export let repo: Repo | undefined = undefined;
export let isLoading: boolean = false;
// TODO: Handle importing of the icons better way
let githubIcon = 'assets/icons/github.png';
let currentRepoExpanded: number | undefined = undefined;

const setCurrentRepoExpanded = (idx: number) =>
  (currentRepoExpanded = idx === currentRepoExpanded ? undefined : idx);

let errors = 0;
let warnings = 0;

repo?.checks.forEach((check) => {
  if (check.severity === severities.ERROR) errors++;
  if (check.severity === severities.WARNING) warnings++;
});
</script>

<div on:click={() => setCurrentRepoExpanded(id)} class={RepoPanel}>
  <h4>{repo?.name}</h4>
  <RedirectLink link={`https://github.com/oakslab/${repo?.name || ''}`}>
    <TooltipWrapper>
      <img class={GithubIcon} src={githubIcon} alt="Github Icon" />
      <Tooltip xPos="center">Open github repo</Tooltip>
    </TooltipWrapper>
  </RedirectLink>

  <div class={IconsWrap}>
    {#if warnings > 0}
      <TooltipWrapper>
        <div>⚠️</div>
        <Tooltip xPos="center">
          <strong>{repo?.name}</strong>
          has
          {warnings}
          warning(s), click to see more
        </Tooltip>
      </TooltipWrapper>
    {/if}
    {#if errors > 0}
      <TooltipWrapper>
        <div>❌</div>
        <Tooltip xPos="center">
          <strong>{repo?.name}</strong>
          has
          {errors}
          error(s), click to see more
        </Tooltip>
      </TooltipWrapper>
    {/if}
    {#if warnings === 0 && errors === 0}
      <TooltipWrapper>
        <div>✅</div>
        <Tooltip xPos="center">
          All is set for
          <strong>{repo?.name}</strong>, click to see more
        </Tooltip>
      </TooltipWrapper>
    {/if}
  </div>
</div>

{#if currentRepoExpanded === id}
  <div transition:slide={{ duration: 500, easing: cubicInOut }}>
    <DataGrid name="Checks" columns={getChecksColumns()} isLoading={isLoading}>
      {#each repo?.checks || [] as check}
        <Row>
          <Cell>{check?.code || '-'}</Cell>
          <Cell>{check?.message || '-'}</Cell>
          <Cell>
            {#if check?.severity}
              <TooltipWrapper>
                <a href={check?.link} target="_blank">
                  <SeverityChip variant={check?.severity}>{check?.severity}</SeverityChip></a>
                {#if check?.severity !== severities.PASS}
                  <Tooltip xPos="center">
                    {@html check?.troubleshoot}
                  </Tooltip>
                {/if}
              </TooltipWrapper>
            {:else}
              <div>-</div>
            {/if}
          </Cell>
        </Row>
      {/each}
    </DataGrid>

    <DataGrid name="Info" columns={getInfoColumns()} isLoading={isLoading}>
      {#each repo?.info || [] as info}
        <Row>
          <Cell>{info?.code || '-'}</Cell>
          <Cell>{info?.message || '-'}</Cell>
        </Row>
      {/each}
    </DataGrid>
  </div>
{/if}
