<script lang="ts">
// @ts-ignore TODO: Missing types
import { Router, Route } from 'svelte-routing';

import Navigation from './components/Navigation/Navigation.svelte';
import Projects from './pages/Projects/Projects.svelte';
import About from './pages/About/About.svelte';

export let url = ''; //This property is necessary declare to avoid ignore the Router
</script>

<Router url={url}>
  <Navigation />
  <div>
    <Route path="about" component={About} />
    <Route path="/">
      <Projects />
    </Route>
  </div>
</Router>
