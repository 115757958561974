<script lang="ts">
import * as S from './About.styles';

import RedirectLink from 'components/RedirectLink.svelte';
</script>

<div>
  <h1>About Sentinel</h1>
  <h2>Sentinel</h2>
  <div class={S.Pronunciation}>/ˈsɛntɪn(ə)l/</div>

  <div>
    <div class={S.ExplanationWrapper}>
      <h4>noun:</h4>

      <p>
        1. a soldier or guard whose job is to stand and keep watch. "soldiers stood sentinel with
        their muskets"
      </p>

      <p>
        2. MEDICINE an indicator of the presence of disease. "a sentinel surveillance programme for
        influenza"
      </p>
    </div>

    <div class={S.ExplanationWrapper}>
      <h4>verb:</h4>
      <p>
        station a soldier or guard by (a place) to keep watch. "a wide course had been roped off and
        sentinelled with police"
      </p>
    </div>
  </div>
</div>

<div class={S.RepoLinkWrapper}>
  <div class={S.RepoName}>API:</div>
  <RedirectLink link="https://github.com/oakslab/ooo-sentinel">
    https://github.com/oakslab/ooo-sentinel
  </RedirectLink>
</div>

<div class={S.RepoLinkWrapper}>
  <div class={S.RepoName}>App:</div>
  <RedirectLink link="https://github.com/oakslab/ooo-sentinel-app">
    https://github.com/oakslab/ooo-sentinel-app
  </RedirectLink>
</div>
