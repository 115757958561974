// @ts-ignore TODO: Missing types
import { css } from 'emotion/dist/emotion.umd.min.js';
import theme from 'theme';
/* Needs to be in separated file, otherwise it is causing error in "SeverityChip.svelte" */
export const ChipSharedStyles = css `
  cursor: pointer;
  color: ${theme.colors.white};

  &:hover {
    color: ${theme.colors.white};
  }
`;
